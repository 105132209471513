@import 'fonts.css';

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Inter Variable', sans-serif;
  background-color: #f7fafc;
}

textarea {
  font-family: 'Inter Variable', sans-serif;
  cursor: auto;
}

input {
  font-family: 'Inter Variable', sans-serif;
}

button {
  font-family: 'Inter Variable', sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

.Toastify {
  display: flex;
  justify-content: center;
}

.Toastify__toast-body {
  padding: 0;
}

.MIToastContainer {
  margin: 0;
  padding: 0;
  width: auto;
  z-index: 11000;
}

.MIToastInner {
  margin: 0 0 10rem 0;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(33, 33, 36, 0.2);
  min-height: auto;
  border-radius: 0.8rem;
}
